import { FAIRE_DIRECT_AUTO_EXTEND_FIRST_ORDER_INCENTIVE_DURATION_IN_MILLISECONDS } from "@faire/web-api/indigofair/settings/FAIRE_DIRECT_AUTO_EXTEND_FIRST_ORDER_INCENTIVE_DURATION_IN_MILLISECONDS";
import millisecondsToHours from "date-fns/millisecondsToHours";

import { getSettingValueWithoutAssignment } from "@faire/retailer/settings/__internal__/getSetting";

const THREE_DAYS_MILLISECONDS = 3 * 24 * 60 * 60 * 1000;

const getSettingFaireDirectAutoExtendFirstOrderIncentiveDurationInMilliseconds =
  (): number => {
    return getSettingValueWithoutAssignment(
      FAIRE_DIRECT_AUTO_EXTEND_FIRST_ORDER_INCENTIVE_DURATION_IN_MILLISECONDS,
      THREE_DAYS_MILLISECONDS
    );
  };

export const getSettingFaireDirectAutoExtendFirstOrderIncentiveDurationInDays =
  () =>
    millisecondsToHours(
      getSettingFaireDirectAutoExtendFirstOrderIncentiveDurationInMilliseconds()
    ) / 24;
