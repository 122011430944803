import * as Cookie from "js-cookie";

export function saveHasLoggedIn() {
  Cookie.set(LOCAL_SESSION_DATA_KEY, "true", { expires: 180 });
}

export function hasPreviouslyLoggedIn() {
  const hasLoggedIn = !!Cookie.get(LOCAL_SESSION_DATA_KEY);
  const hasMFA = !!Cookie.get(MFA_SUPPRESSION_KEY);
  return hasLoggedIn || hasMFA;
}

export const LOCAL_SESSION_DATA_KEY = "localSession";
export const MFA_SUPPRESSION_KEY = "faire_mfa_suppression_secret";
export const LOCAL_STORAGE_KEY = "logged-out-previously-visited";
