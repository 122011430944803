import escapeRegExp from "lodash/escapeRegExp";
import * as React from "react";

export const highlightWord = (
  highlight: string,
  word: string
): React.ReactNode[] => {
  if (!highlight.trim() || !word) {
    return [word];
  }

  // Wrapping in try-catch block to prevent any fatal errors from bad input
  try {
    const regExp = new RegExp(escapeRegExp(highlight), "gi");

    let charPointer = 0;
    const pieces = [];
    let exec = regExp.exec(word);
    while (exec) {
      const matchStart = exec.index;
      if (matchStart > charPointer) {
        pieces.push(word.substr(charPointer, matchStart - charPointer));
      }
      pieces.push(<strong key={matchStart}>{exec[0]}</strong>);
      charPointer = matchStart + (exec[0]?.length ?? 0);
      exec = regExp.exec(word);
    }
    if (charPointer < word.length) {
      pieces.push(word.substr(charPointer, word.length));
    }

    return pieces;
  } catch (e) {
    return [];
  }
};

const highlightStringExceptWord = (
  highlight: string,
  word: string
): React.ReactNode[] => {
  if (!highlight.trim() || !word) {
    return [word];
  }

  // Wrapping in try-catch block to prevent any fatal errors from bad input
  try {
    const regExp = new RegExp("\\b" + escapeRegExp(highlight).trim(), "gi");

    let charPointer = 0;
    const pieces = [];
    let exec = regExp.exec(word);
    while (exec) {
      const matchStart = exec.index;
      if (matchStart > charPointer) {
        pieces.push(
          <strong key={matchStart}>
            {word.substr(charPointer, matchStart - charPointer)}
          </strong>
        );
      }
      pieces.push(exec[0]);
      charPointer = matchStart + (exec[0]?.length ?? 0);
      exec = regExp.exec(word);
    }
    if (charPointer < word.length) {
      pieces.push(
        <strong key={charPointer}>
          {word.substr(charPointer, word.length)}
        </strong>
      );
    }
    return pieces;
  } catch (e) {
    return [];
  }
};

interface IProps {
  message: string;
  highlight: string;
  className?: string;
  reverseHighlight?: boolean;
}

const StringHighlight: React.FunctionComponent<IProps> = ({
  message,
  highlight,
  className,
  reverseHighlight = false,
}) => {
  const sections = message.split("/");
  const lastSection = sections.pop();

  if (!highlight || !lastSection) {
    return <span className={className}>{message}</span>;
  }

  sections.push("");
  const prefix = sections.join("/");

  return (
    <span className={className}>
      {prefix}
      {reverseHighlight
        ? highlightStringExceptWord(highlight, lastSection)
        : highlightWord(highlight, lastSection)}
    </span>
  );
};

export default StringHighlight;
