import {
  useQuery,
  getQueryKey,
  getQueryFn,
} from "@faire/web-api/api/v2/product/productToken/get-hooks";
import { IProductPageResponse } from "@faire/web-api/indigofair/data/IProductPageResponse";
import { logError } from "@faire/web/common/logging";
import { QueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { readBundledData } from "@faire/retailer/lib/bundledData";
import { useSettingProductPageReactQuery } from "@faire/retailer/settings/useSettingProductPageReactQuery";
import { ProductCacheStore } from "@faire/retailer/stores/domain/ProductCacheStore";

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;

export const useLeanProductQuery = (
  productToken: string,
  options?: Parameters<typeof useQuery>[1]
) => {
  return useQuery(productToken, {
    initialData: () => {
      const prefetched = readBundledData("product_page", { keep: true });
      if (productToken === prefetched?.product?.token) {
        return prefetched;
      }
      return undefined;
    },
    enabled: false,
    staleTime: FIVE_MINUTES_IN_MS,
    refetchOnMount: false,
    ...options,
  });
};

export const prefetchLeanProduct = (
  queryClient: QueryClient,
  productToken: string
) => {
  queryClient.prefetchQuery({
    queryKey: getQueryKey(productToken),
    queryFn: getQueryFn(productToken),
  });
};

/**
 * A hook that returns the product data from /product/:productToken, either using react query or the legacy method
 * depending on the value of PRODUCT_PAGE_REACT_QUERY_241007.
 */
export const useLeanProductWithMaybeReactQuery = (
  productToken: string | undefined
) => {
  const productPageReactQuerySetting = useSettingProductPageReactQuery();
  const { data: reactQueryProductData } = useLeanProductQuery(
    productToken ?? "",
    {
      enabled: productPageReactQuerySetting && !!productToken,
    }
  );
  const [legacyProductData, setLegacyProductData] = useState<
    IProductPageResponse | undefined
  >(undefined);

  useEffect(() => {
    if (!productPageReactQuerySetting && !!productToken) {
      ProductCacheStore.get()
        .fetchLeanProduct(productToken)
        .then(setLegacyProductData)
        .catch(logError);
    }
  }, [productPageReactQuerySetting, productToken]);
  return productPageReactQuerySetting
    ? reactQueryProductData
    : legacyProductData;
};
