import { returnRetrievalRankingDebugInfo } from "@faire/retailer-shared/search/loggedInBrandLeanProductTilesRequest";
import { path as getPathPublishedBrandProductCollectionMetadata } from "@faire/web-api/api/brand/brandToken/published-product-collection-metadata/get";
import getBuyItAgainProducts from "@faire/web-api/api/buy-it-again/product-data/post";
import fetchBrandPageLayout from "@faire/web-api/api/layout/brand-page/post";
import fetchCategoryPageLayoutElements from "@faire/web-api/api/layout/category-page/post";
import getRetailerRestrictions from "@faire/web-api/api/retailer/retailerToken/retailer-restrictions/post";
import getLeanProduct from "@faire/web-api/api/v2/product/productToken/get";
import brandSearch, {
  QueryParameters as BrandSearchParameters,
} from "@faire/web-api/api/v2/search/brands/get";
import getFeaturedProducts, {
  QueryParameters as FeaturedProductsParameters,
} from "@faire/web-api/api/v2/search/brandToken/featured-products/get";
import fetchProductCatNav from "@faire/web-api/api/v2/search/products/cat-nav/post";
import { path as getPathProductsFromBrandFilters } from "@faire/web-api/api/v2/search/products/from-brand/filters/post";
import searchProductsFromBrand, {
  path as getPathProductsFromBrand,
} from "@faire/web-api/api/v2/search/products/from-brand/post";
import queryProductsFilterService from "@faire/web-api/api/v3/search/products/filters/post";
import queryProductsSearchService from "@faire/web-api/api/v3/search/products/post";
import { IGetBuyItAgainProductsRequest } from "@faire/web-api/faire/bia/client/IGetBuyItAgainProductsRequest";
import { IGetBuyItAgainProductsResponse } from "@faire/web-api/faire/bia/client/IGetBuyItAgainProductsResponse";
import { IGetProductFilterSectionInfoResponse } from "@faire/web-api/indigofair/data/IGetProductFilterSectionInfoResponse";
import { IGetSearchProductsInfoResponse } from "@faire/web-api/indigofair/data/IGetSearchProductsInfoResponse";
import { IProductFirstCatNavRequest } from "@faire/web-api/indigofair/data/IProductFirstCatNavRequest";
import { IProductFirstCatNavWithLeanProductTileResponse } from "@faire/web-api/indigofair/data/IProductFirstCatNavWithLeanProductTileResponse";
import { IProductPageResponse } from "@faire/web-api/indigofair/data/IProductPageResponse";
import { IQueryProductsRequest } from "@faire/web-api/indigofair/data/IQueryProductsRequest";
import { ISearchBrandsV2Response } from "@faire/web-api/indigofair/data/ISearchBrandsV2Response";
import { ISearchProductsFromBrandRequest } from "@faire/web-api/indigofair/data/ISearchProductsFromBrandRequest";
import { EndpointOptions } from "@faire/web-api/types";
import { cachingApi, cachingApiWithLookup } from "@faire/web/common/cachingApi";
import { singletonGetter } from "@faire/web/common/singletons/getter";
import { createStoreHook } from "@faire/web/ui/hooks/useStore";
import type { QueryClient } from "@tanstack/react-query";
import memoizee from "memoizee";

import { readBundledData } from "@faire/retailer/lib/bundledData";
import { ServerSideLogger } from "@faire/retailer/services/ServerSideLogger";
import { getSettingShouldDisplayRetrievalAndRankingDebugInfo } from "@faire/retailer/settings/getSettingShouldDisplayRetrievalAndRankingDebugInfo";

import { UserStore } from "./UserStore";

const queryProductsWrapper = (
  data: IQueryProductsRequest,
  abortSignal?: AbortSignal
): Promise<IGetSearchProductsInfoResponse> => {
  return queryProductsSearchService(data, { signal: abortSignal });
};

const queryFiltersWrapper = (
  data: IQueryProductsRequest,
  abortSignal?: AbortSignal
): Promise<IGetProductFilterSectionInfoResponse> => {
  return queryProductsFilterService(data, { signal: abortSignal });
};

const fetchProductCatNavWrapper = (
  data: IProductFirstCatNavRequest,
  requestOptions?: EndpointOptions
): Promise<IProductFirstCatNavWithLeanProductTileResponse> => {
  data.return_retrieval_and_ranking_debug_info =
    returnRetrievalRankingDebugInfo(
      UserStore.get().user,
      getSettingShouldDisplayRetrievalAndRankingDebugInfo()
    );
  // New search service API that searches the products.
  return fetchProductCatNav(data, requestOptions);
};

// need this for tests
const brandSearchWrapper = (
  payload: BrandSearchParameters,
  requestOptions?: EndpointOptions
): Promise<ISearchBrandsV2Response> => brandSearch(payload, requestOptions);

export class ProductCacheStore {
  /**
   * @trackfunction
   */
  static get = singletonGetter(ProductCacheStore);

  private prefetchedLeanProductMemo: () => IProductPageResponse | undefined =
    memoizee(() => readBundledData("product_page", { keep: true }), {
      maxAge: 1000 * 60 * 5,
    });

  private fetchLeanProductMemo = async (productToken: string) => {
    const prefetched = this.prefetchedLeanProductMemo();
    if (prefetched?.product && productToken === prefetched.product.token) {
      return prefetched;
    }
    return getLeanProduct(productToken);
  };

  /**
   * @deprecated This is being moved to react query (behing the setting PRODUCT_PAGE_REACT_QUERY_241007).
   * Please contact Blair McAlpine for any questions!
   */
  getRetailerRestrictions = cachingApi(getRetailerRestrictions);

  // NOTE(Blair) Not fully converted to useQuery, still need ProductState
  /**
   * @deprecated This is being moved to react query (behing the setting PRODUCT_PAGE_REACT_QUERY_241007).
   * Please use useLeanProductWithMaybeReactQuery instead.
   * Please contact Blair McAlpine for any questions!
   */
  fetchLeanProduct = cachingApi(this.fetchLeanProductMemo);
  fetchProductCatNav = cachingApi(fetchProductCatNavWrapper);

  /**
   * @deprecated This is being moved to react query (behing the setting PRODUCT_PAGE_REACT_QUERY_241007).
   * Please contact Blair McAlpine for any questions!
   */
  fetchBrandPageLayout = cachingApi(fetchBrandPageLayout);

  fetchCategoryPageLayoutElements = cachingApi(fetchCategoryPageLayoutElements);

  // searchProductsFromBrandCache needs wrapper to enable manual cache invalidation
  // when a product's state is modified
  private searchProductsFromBrandCache = cachingApiWithLookup(
    (request: ISearchProductsFromBrandRequest) => {
      if (!request.brand_token) {
        return Promise.reject("Empty brand_token");
      }
      return searchProductsFromBrand(request);
    }
  );

  private getBuyItAgainProductsCache = cachingApiWithLookup(
    getBuyItAgainProducts
  );

  private queryProductsMemoized = cachingApi(queryProductsWrapper);

  private queryFiltersMemoized = cachingApi(queryFiltersWrapper);

  private brandSearchMemoized = cachingApi(brandSearchWrapper);

  private getFeaturedLeanProductTilesMemoized = cachingApi(getFeaturedProducts);

  searchProductsFromBrand = (payload: ISearchProductsFromBrandRequest) => {
    this.searchProductsFromBrandCache.lookup.add(payload);
    return this.searchProductsFromBrandCache.cache(payload);
  };

  getBuyItAgainProducts = (
    payload: IGetBuyItAgainProductsRequest
  ): Promise<IGetBuyItAgainProductsResponse> => {
    this.getBuyItAgainProductsCache.lookup.add(payload);
    return this.getBuyItAgainProductsCache.cache(payload);
  };

  // Search results view caches

  queryProducts = (
    payload: IQueryProductsRequest,
    abortSignal?: AbortSignal
  ) => {
    return this.queryProductsMemoized(payload, abortSignal);
  };

  queryFilters = (
    payload: IQueryProductsRequest,
    abortSignal?: AbortSignal
  ) => {
    return this.queryFiltersMemoized(payload, abortSignal);
  };

  // Category view results view caches
  brandSearch = (
    payload: BrandSearchParameters,
    requestOptions?: EndpointOptions
  ) => {
    return this.brandSearchMemoized(payload, requestOptions);
  };

  getFeaturedLeanProductTiles = (
    brandToken: string,
    queryParams?: FeaturedProductsParameters
  ) => {
    return this.getFeaturedLeanProductTilesMemoized(brandToken, queryParams);
  };

  // Invalidate a brand's product cache when products of that
  // brand have had their state modified
  invalidateCache = (
    brandToken: string | undefined,
    queryClient: QueryClient | null
  ) => {
    if (brandToken === undefined) {
      return;
    }

    // Invalidate the entire cache of search calls if any product is favorited
    this.queryProductsMemoized.clear();
    this.queryFiltersMemoized.clear();
    this.brandSearchMemoized.clear();
    this.getFeaturedLeanProductTilesMemoized.clear();

    this.searchProductsFromBrandCache.lookup.forEach((payload) => {
      if (payload.brand_token === brandToken) {
        this.searchProductsFromBrandCache.cache.delete(payload);
      }
    });

    this.getBuyItAgainProductsCache.lookup.forEach((payload) => {
      if (payload.brand_token === brandToken) {
        this.getBuyItAgainProductsCache.cache.delete(payload);
      }
    });

    if (queryClient) {
      queryClient.removeQueries({
        queryKey: [getPathPublishedBrandProductCollectionMetadata(brandToken)],
      });
      queryClient.removeQueries({
        queryKey: [getPathProductsFromBrand(), { brand_token: brandToken }],
      });
      queryClient.removeQueries({
        queryKey: [
          getPathProductsFromBrandFilters(),
          { brand_token: brandToken },
        ],
      });
    }
  };

  invalidateCacheAfterPlacingAndCancelingOrders = () => {
    this.brandSearchMemoized.clear();
    this.queryProductsMemoized.clear();
    this.queryFiltersMemoized.clear();
  };

  invalidateCacheAfterInsiderEnrollment = () => {
    this.brandSearchMemoized.clear();
  };

  prefetchedLeanProduct = (
    productToken: string
  ): IProductPageResponse | undefined => {
    const prefetched = this.prefetchedLeanProductMemo();
    if (prefetched?.product && productToken === prefetched.product.token) {
      return prefetched;
    } else {
      ServerSideLogger.get().warn(
        `Prefetched lean product data missing for token ${productToken}`
      );
      return undefined;
    }
  };
}

export const useProductCacheStore = createStoreHook(ProductCacheStore);
