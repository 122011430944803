import { Core } from "@faire/design-tokens";
import { Opacity } from "@faire/web/slate/Color";
import { InputWrapper } from "@faire/web/slate/Input";
import { Column } from "@faire/web/slate/Layout";
import { getSpacing } from "@faire/web/slate/spacing";
import { SpacerValueOrKey } from "@faire/web/slate/spacing/getSpacing";
import { responsiveMediaQueries } from "@faire/web/slate/Theme/responsiveMediaQueries";
import styled, { css } from "styled-components";

import { BrandPageSearch } from "./BrandPageSearch";

export const BRAND_CART_WIDTH_WIDE = {
  large: "320px",
  xlarge: "360px",
};

export const MakerContainer = styled.div`
  width: 100%;
  ${responsiveMediaQueries.desktop} {
    width: calc(100% - ${BRAND_CART_WIDTH_WIDE.large});
  }
  ${responsiveMediaQueries.xLargeAndAbove} {
    width: calc(100% - ${BRAND_CART_WIDTH_WIDE.xlarge});
  }
`;

export const commonPaddingStyle = (
  extraPadding?: boolean,
  noPadding?: boolean
) => {
  if (noPadding) {
    return "";
  }
  return css`
    padding: ${getSpacing(0, "2x")};

    ${responsiveMediaQueries.tabletAndAbove} {
      padding: ${extraPadding
        ? getSpacing(0, "5x")
        : getSpacing(0, "3x", 0, "5x")};
    }

    @media (min-width: 1680px) {
      padding: 0;
    }
  `;
};

export const ContentContainer = styled.div<{
  $extraPadding?: boolean;
  $noPadding?: boolean;
}>`
  width: 100%;
  ${({ $extraPadding: extraPadding, $noPadding: noPadding }) =>
    commonPaddingStyle(extraPadding, noPadding)}
`;

export const BrandComponentLowerContainer = styled(Column)`
  width: 100%;
  max-width: calc(1280px + 80px);
  margin: 0 auto;
  padding: ${getSpacing(0, "5x")};

  ${responsiveMediaQueries.mobile} {
    padding: ${getSpacing(0, "2x")};
  }
`;

export const lowerStyle = (val: SpacerValueOrKey) => {
  return css`
    & > div:first-child {
      padding-top: ${getSpacing(val)};
    }
    & > div:last-child {
      padding-bottom: ${getSpacing(val)};
    }
    & > div:not(:first-child) {
      border-top: 1px solid ${Core.border.muted};
      padding-top: ${getSpacing(val)};
      margin-top: ${getSpacing(val)};
    }
  `;
};

export const BrandPageLowerPart = styled.div`
  ${responsiveMediaQueries.mobile} {
    ${lowerStyle("3x")}
  }
  ${responsiveMediaQueries.tablet} {
    ${lowerStyle("4x")}
  }
  ${responsiveMediaQueries.desktopAndAbove} {
    ${lowerStyle("5x")}
  }
`;

export const InputSearch = styled(BrandPageSearch)`
  ${InputWrapper} {
    border-radius: 40px;
    ${responsiveMediaQueries.tabletAndBelow} {
      background-color: ${Core.surface.subdued + Opacity.O_50};
      border: none;
    }

    ${responsiveMediaQueries.tablet} {
      min-width: 300px;
    }
  }
`;
