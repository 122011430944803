import { Retailer as RetailerSettings } from "@faire/web-api/indigofair/settings/Serialized";
import { Boolean as BooleanSettings } from "@faire/web-api/indigofair/settings/Setting";

import { useSetting } from "./__internal__/useSetting";

type SerializedRetailerBooleanSettings = Extract<
  RetailerSettings,
  BooleanSettings
>;

export function useBooleanSetting(
  setting: SerializedRetailerBooleanSettings
): boolean {
  return useSetting(setting, false);
}
