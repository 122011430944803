import { getGlobalProperty } from "@faire/web/common/globals/getGlobalProperty";
import { isWindowUndefined } from "@faire/web/common/server-side-rendering/isWindowUndefined";
import { singletonGetter } from "@faire/web/common/singletons/getter";

interface Logger {
  debug(msg: unknown, details?: unknown): void;
  info(msg: unknown, details?: unknown): void;
  warn(msg: unknown, details?: unknown): void;
  error(msg: unknown, details?: unknown): void;
}

export class ServerSideLogger {
  static get = singletonGetter(ServerSideLogger);

  private logger: Logger | undefined;

  constructor() {
    if (isWindowUndefined()) {
      this.logger = getGlobalProperty("serverSideLogger");
    }
  }

  warn = (msg: unknown, details?: unknown): void => {
    this.logger?.warn(msg, details);
  };
}
