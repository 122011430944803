import { trackPricingOpeningOrderIncentivesViewOfferBannerView } from "@faire/web-api/events/pricing/view/openingOrderIncentivesViewOfferBanner";
import { IFaireMoney } from "@faire/web-api/indigofair/data/IFaireMoney";
import { intlDate } from "@faire/web/common/localization/date-fns/intlDate";
import { makeObservable } from "@faire/web/common/makeObservable";
import { getCurrentPath } from "@faire/web/common/Path";
import { isWindowUndefined } from "@faire/web/common/server-side-rendering/isWindowUndefined";
import { singletonGetter } from "@faire/web/common/singletons/getter";
import { Storage } from "@faire/web/common/Storage";
import { trackEvent } from "@faire/web/common/trackEvent";
import { createStoreHook } from "@faire/web/ui/hooks/useStore";
import differenceInHours from "date-fns/differenceInHours";
import getTime from "date-fns/getTime";
import once from "lodash/once";
import { action, computed, observable } from "mobx";

import { setIntervalIfPossible } from "@faire/retailer/lib/async-functions/setIntervalIfPossible";
import { getOpeningOrderIncentivesInfo } from "@faire/retailer/serialized-data/getOpeningOrderIncentivesInfo";

export interface IOpeningOrderIncentivesProps {
  endDate: string;
  endDateWithTimezone: string;
  discountPercentage: number;
  discountCapAmount?: IFaireMoney;
}

/**
 * @deprecated prefer to use `useOpeningOrderIncentivesStore` or `withStores` instead
 */
export class OpeningOrderIncentivesStore {
  /**
   * @trackfunction
   */
  static get = singletonGetter(OpeningOrderIncentivesStore);

  @observable
  currentTime: number = getTime(new Date());

  private interval?: number;
  private isDrivenFromEmailStorageKey: string =
    "OpeningOrderIncentivesIsDrivenFromEmail";

  constructor() {
    makeObservable(this);
    this.initialize();
    if (
      getCurrentPath().searchParams.get("oeln") ===
      "opening-order-incentives-marketing"
    ) {
      Storage.session.setItem(this.isDrivenFromEmailStorageKey, "true");
      trackEvent("opening-order-incentives/marketing-email/driven-from");
    }
  }

  @action
  private initialize = () => {
    // eslint-disable-next-line @faire/ssr-no-restricted-globals
    if (typeof window !== "undefined" && !isWindowUndefined()) {
      this.interval = setIntervalIfPossible(this.timer.bind(this), 1000);
    }
  };

  @action
  private timer = () => {
    this.setCurrentTime(getTime(new Date()));

    // Cancel the timer if it no longer needs to run.
    if (!this.showOpeningOrderIncentives && this.interval) {
      clearInterval(this.interval);
    }
  };

  @action
  private setCurrentTime = (currentTime: number) => {
    this.currentTime = currentTime;
  };

  @computed
  private get openingOrderIncentivesData() {
    return getOpeningOrderIncentivesInfo();
  }

  @computed
  get showOpeningOrderIncentives() {
    const { is_retailer_eligible, expires_at } =
      this.openingOrderIncentivesData;
    return is_retailer_eligible && expires_at && this.currentTime < expires_at;
  }

  @computed
  get numberOfHoursRemaining(): number | undefined {
    const { expires_at } = this.openingOrderIncentivesData;
    return expires_at && differenceInHours(expires_at, this.currentTime);
  }

  trackOOIBannerViewEvent = once(
    trackPricingOpeningOrderIncentivesViewOfferBannerView
  );

  @computed
  get openingOrderIncentivesProps(): IOpeningOrderIncentivesProps | undefined {
    const { discount_bps, expires_at, discount_bps_cap_amount } =
      this.openingOrderIncentivesData;
    if (!expires_at || !discount_bps_cap_amount || !discount_bps) {
      return undefined;
    }

    return {
      endDate: intlDate(expires_at, "MMMM d"),
      endDateWithTimezone: intlDate(expires_at, "PPpx"),
      discountPercentage: discount_bps / 100,
      discountCapAmount: discount_bps_cap_amount,
    };
  }

  @computed
  get isDrivenFromMarketingEmail(): boolean {
    return !!Storage.session.getItem(this.isDrivenFromEmailStorageKey);
  }
}

export const useOpeningOrderIncentivesStore = createStoreHook(
  OpeningOrderIncentivesStore
);
