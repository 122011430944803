import { IUserMilestone } from "@faire/web-api/indigofair/data/IUserMilestone";
import { PromoModalType } from "@faire/web-api/indigofair/data/PromoModalType";
import { route as boardsRoute } from "@faire/web-api/routes/boards";
import { route as followingBoardsRoute } from "@faire/web-api/routes/boards/following";
import { route as brandInvoiceTokenRoute } from "@faire/web-api/routes/brand-invoice/brandInvoiceToken";
import { route as brandRoute } from "@faire/web-api/routes/brand/brandToken";
import { route as brandAppLandingPageRoute } from "@faire/web-api/routes/brands/app";
import { route as careersRoute } from "@faire/web-api/routes/careers";
import { route as c1Route } from "@faire/web-api/routes/category/c1CategoryUrlId";
import { route as c2Route } from "@faire/web-api/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId";
import { route as c3Route } from "@faire/web-api/routes/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId";
import { route as confirmIdentityRoute } from "@faire/web-api/routes/confirm-identity";
import { route as expandingBusinessesOfferRoute } from "@faire/web-api/routes/expanding-businesses-offer";
import { route as expandingBusinessesOfferApplyRoute } from "@faire/web-api/routes/expanding-businesses-offer/apply";
import { route as netTermsOpenWithFaireApplicationRoute } from "@faire/web-api/routes/net-terms/owf-application";
import { route as openWithFaireRoute } from "@faire/web-api/routes/open-with-faire";
import { route as pointOfSaleOnboardingRoute } from "@faire/web-api/routes/point-of-sale/onboarding";
import { route as pointOfSaleOrderManagerRoute } from "@faire/web-api/routes/point-of-sale/order-manager/orderToken";
import { route as collectionRoute } from "@faire/web-api/routes/product-based-collection/product_based_collection_token";
import { route as productRoute } from "@faire/web-api/routes/product/productToken";
import { route as retailerSettingsInsiderMembershipRoute } from "@faire/web-api/routes/retailer/settings/insider-membership";
import { route as paymentMethodsRoute } from "@faire/web-api/routes/retailer/settings/payment-methods";
import { route as homeRoute } from "@faire/web-api/routes/root";
import { route as searchRoute } from "@faire/web-api/routes/search";
import { route as smallBusinessGrantRoute } from "@faire/web-api/routes/small-business-grant";
import { route as insiderTermsOfServiceRoute } from "@faire/web-api/routes/tos-insider";
import { route as unpaidInvoicesRoute } from "@faire/web-api/routes/user/account/invoices/unpaid";
import { route as ordersRoute } from "@faire/web-api/routes/user/account/orders";
import { getWindowOrThrow } from "@faire/web/common/globals/getWindow";
import { parseQueryParams } from "@faire/web/common/query-params/parseQueryParams";
import { pathMatchesRoute } from "@faire/web/common/routes/RouteMatcher";
import { matchPathWithLocale } from "@faire/web/ui/routing/react-utils";
import isUndefined from "lodash/isUndefined";
import { useCallback } from "react";
import { matchPath } from "react-router-dom";

import { isE2E } from "@faire/retailer/lib/isE2E";
import { isFOIModalE2E } from "@faire/retailer/lib/isFOIModalE2E";
import { useIsLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { home } from "@faire/retailer/paths";
import { getPromoModalInformation } from "@faire/retailer/serialized-data/getPromoModalInformation";
import { useOpeningOrderIncentivesStore } from "@faire/retailer/stores/domain/OpeningOrderIncentivesStore";
import { useRetailerStore } from "@faire/retailer/stores/domain/RetailerStore";
import { useUserStore } from "@faire/retailer/stores/domain/UserStore";

import { useMilestone } from "../../domain/useMilestone";
import { usePromoModalContextStore } from "../PromoModalContextStore";

export const MOBILE_APP_PROMOTION_ALLOWED_PATHS = [
  searchRoute,
  brandRoute,
  productRoute,
  ordersRoute,
  c1Route,
  c2Route,
  c3Route,
  boardsRoute,
  followingBoardsRoute,
  homeRoute,
  collectionRoute,
];

export const MOBILE_APP_PROMOTION_BLOCKED_PATHS = [
  "/retailer/:retailerToken/category/International%20Best%20Sellers",
  "/category/International%20Best%20Sellers",
];

const PROMOTION_BLOCKED_PATHS = [
  retailerSettingsInsiderMembershipRoute,
  confirmIdentityRoute,
  careersRoute,
  brandAppLandingPageRoute,
  insiderTermsOfServiceRoute,
  pointOfSaleOnboardingRoute,
  pointOfSaleOrderManagerRoute,
  brandInvoiceTokenRoute,
  expandingBusinessesOfferRoute,
  expandingBusinessesOfferApplyRoute,
  smallBusinessGrantRoute,
  openWithFaireRoute,
];

const OVERDUE_INVOICES_BLOCKED_PATHS = [
  paymentMethodsRoute,
  unpaidInvoicesRoute,
];

// eslint-disable-next-line complexity
const useModalToDisplay = (
  modalType: keyof typeof PromoModalType | undefined
): PromoModalType | null => {
  const { hasOccurred: hasSeenOpeningOrderDiscountModal } = useMilestone(
    IUserMilestone.RetailerMilestone.OPENING_ORDER_DISCOUNT_MODAL_SEEN
  );
  const { hasOccurred: hasSeenOrderDiscountCountdownModal } = useMilestone(
    IUserMilestone.RetailerMilestone.OPENING_ORDER_DISCOUNT_COUNTDOWN_MODAL_SEEN
  );
  const { hasOccurred: hasSeenPrepaidCardsRestrictedModal } = useMilestone(
    IUserMilestone.RetailerMilestone.PREPAID_CARDS_RESTRICTED_MODAL_SEEN
  );
  const { hasOccurred: hasSeenAchProactiveLimitIncreaseModal } = useMilestone(
    IUserMilestone.RetailerMilestone.HAS_SEEN_ACH_PROACTIVE_LIMIT_INCREASE_MODAL
  );
  const { hasOccurred: hasSeenWarehouseSaleModal } = useMilestone(
    IUserMilestone.RetailerMilestone.HAS_SEEN_WAREHOUSE_SALE_MODAL_NOV_22
  );
  const { hasOccurred: hasSeenShopifyOnboardingBenefitsModal } = useMilestone(
    IUserMilestone.RetailerMilestone.SHOPIFY_ONBOARDING_BENEFITS_MODAL_SEEN
  );
  const { hasOccurred: hasDismissedSaleAnnouncement } = useMilestone(
    IUserMilestone.RetailerMilestone.SALE_ANNOUNCEMENT_DISMISSED
  );

  const {
    isDrivenFromMarketingEmail,
    numberOfHoursRemaining,
    showOpeningOrderIncentives,
  } = useOpeningOrderIncentivesStore([
    "isDrivenFromMarketingEmail",
    "numberOfHoursRemaining",
    "showOpeningOrderIncentives",
  ]);
  const isLoggedInRetailer = useIsLoggedInRetailer();
  const { userCanPlaceOrder } = useUserStore(["userCanPlaceOrder"]);
  const { isRetailerCreatedWithinLast30Days, showWarehouseFlashSale } =
    useRetailerStore([
      "isRetailerCreatedWithinLast30Days",
      "showWarehouseFlashSale",
    ]);

  const searchParamsContain = (param: string) =>
    !!parseQueryParams(getWindowOrThrow().location.search).get(param);

  if (modalType === PromoModalType.SDUI_MODAL && isLoggedInRetailer) {
    return PromoModalType.SDUI_MODAL;
  }

  if (
    modalType === PromoModalType.FIRST_ORDER_INCENTIVE_MODAL &&
    (!isE2E() || isFOIModalE2E())
  ) {
    return PromoModalType.FIRST_ORDER_INCENTIVE_MODAL;
  }

  if (
    modalType === PromoModalType.FAIRE_DIRECT_INCENTIVE_MODAL &&
    isLoggedInRetailer
  ) {
    return PromoModalType.FAIRE_DIRECT_INCENTIVE_MODAL;
  }

  if (
    (modalType === PromoModalType.FAIRE_DIRECT_MARKETPLACE_DISCOUNT_MODAL ||
      modalType ===
        PromoModalType.FAIRE_DIRECT_EVERGREEN_MARKETPLACE_DISCOUNT_MODAL) &&
    isLoggedInRetailer
  ) {
    return PromoModalType.FAIRE_DIRECT_MARKETPLACE_DISCOUNT_MODAL;
  }

  if (
    isLoggedInRetailer &&
    modalType === PromoModalType.PAYMENT_DISPUTE_MODAL
  ) {
    return PromoModalType.PAYMENT_DISPUTE_MODAL;
  }

  if (
    !OVERDUE_INVOICES_BLOCKED_PATHS.some(
      (path) =>
        !!matchPath(getWindowOrThrow().location.pathname, { path, exact: true })
    ) &&
    isLoggedInRetailer &&
    modalType === PromoModalType.PAST_DUE_INVOICE_MODAL
  ) {
    return PromoModalType.PAST_DUE_INVOICE_MODAL;
  }

  if (
    isLoggedInRetailer &&
    modalType === PromoModalType.POST_ORDER_INSIDER_ENROLLMENT
  ) {
    return PromoModalType.POST_ORDER_INSIDER_ENROLLMENT;
  }

  if (
    isLoggedInRetailer &&
    showOpeningOrderIncentives &&
    !hasSeenOpeningOrderDiscountModal &&
    (numberOfHoursRemaining ?? 0) >= 48 &&
    !isDrivenFromMarketingEmail
  ) {
    return PromoModalType.OPENING_ORDER_DISCOUNT_MODAL;
  }

  if (
    numberOfHoursRemaining &&
    isLoggedInRetailer &&
    showOpeningOrderIncentives &&
    !hasSeenOrderDiscountCountdownModal &&
    numberOfHoursRemaining < 48 &&
    !isDrivenFromMarketingEmail
  ) {
    return PromoModalType.OPENING_ORDER_DISCOUNT_COUNTDOWN_MODAL;
  }

  if (
    isLoggedInRetailer &&
    showWarehouseFlashSale &&
    !isRetailerCreatedWithinLast30Days &&
    !!matchPath(getWindowOrThrow().location.pathname, {
      path: home(),
      exact: true,
    }) &&
    !hasSeenWarehouseSaleModal &&
    modalType === PromoModalType.WAREHOUSE_SALE_MODAL
  ) {
    return PromoModalType.WAREHOUSE_SALE_MODAL;
  }

  if (
    isLoggedInRetailer &&
    hasSeenPrepaidCardsRestrictedModal &&
    modalType === PromoModalType.PREPAID_CARDS_RESTRICTED_MODAL
  ) {
    return PromoModalType.PREPAID_CARDS_RESTRICTED_MODAL;
  }

  if (
    isLoggedInRetailer &&
    modalType === PromoModalType.PAYMENT_PLAN_PAYMENT_FAILED_MODAL
  ) {
    return PromoModalType.PAYMENT_PLAN_PAYMENT_FAILED_MODAL;
  }

  if (
    isLoggedInRetailer &&
    modalType === PromoModalType.PAYMENT_PLAN_FULLY_PAID_MODAL
  ) {
    return PromoModalType.PAYMENT_PLAN_FULLY_PAID_MODAL;
  }

  if (
    isLoggedInRetailer &&
    !hasSeenAchProactiveLimitIncreaseModal &&
    !searchParamsContain("achProactiveLimitIncrease") &&
    modalType === PromoModalType.ACH_PROACTIVE_LIMIT_INCREASE_LANDING_MODAL
  ) {
    return PromoModalType.ACH_PROACTIVE_LIMIT_INCREASE_LANDING_MODAL;
  }

  if (
    isLoggedInRetailer &&
    !searchParamsContain("achTermsPreApproval") &&
    modalType === PromoModalType.ACH_TERMS_PRE_APPROVAL_LANDING_MODAL
  ) {
    return PromoModalType.ACH_TERMS_PRE_APPROVAL_LANDING_MODAL;
  }

  if (
    isLoggedInRetailer &&
    !searchParamsContain("termsPreApproval") &&
    modalType === PromoModalType.TERMS_PRE_APPROVAL_LANDING_MODAL
  ) {
    return PromoModalType.TERMS_PRE_APPROVAL_LANDING_MODAL;
  }

  if (
    isLoggedInRetailer &&
    userCanPlaceOrder &&
    modalType === PromoModalType.INSIDER_TERMS_AND_CONDITIONS_ACCEPTANCE
  ) {
    return PromoModalType.INSIDER_TERMS_AND_CONDITIONS_ACCEPTANCE;
  }

  if (
    !hasSeenShopifyOnboardingBenefitsModal &&
    modalType === PromoModalType.SHOPIFY_NEW_RETAILER_BENEFITS_MODAL
  ) {
    return PromoModalType.SHOPIFY_NEW_RETAILER_BENEFITS_MODAL;
  }

  if (
    !hasSeenShopifyOnboardingBenefitsModal &&
    modalType === PromoModalType.SHOPIFY_EXISTING_RETAILER_BENEFITS_MODAL
  ) {
    return PromoModalType.SHOPIFY_EXISTING_RETAILER_BENEFITS_MODAL;
  }

  if (
    isLoggedInRetailer &&
    modalType === PromoModalType.FAIRE_PAY_DEFAULTED_MODAL
  ) {
    return PromoModalType.FAIRE_PAY_DEFAULTED_MODAL;
  }

  if (
    isLoggedInRetailer &&
    !searchParamsContain("termsAssignment") &&
    modalType === PromoModalType.NON_BM_INITIAL_LIMIT_LANDING_MODAL
  ) {
    return PromoModalType.NON_BM_INITIAL_LIMIT_LANDING_MODAL;
  }

  if (
    isLoggedInRetailer &&
    userCanPlaceOrder &&
    modalType === PromoModalType.INSIDER_PARTNERSHIP_LAUNCH_ANNOUNCEMENT
  ) {
    return PromoModalType.INSIDER_PARTNERSHIP_LAUNCH_ANNOUNCEMENT;
  }

  if (
    isLoggedInRetailer &&
    modalType === PromoModalType.SALE_ANNONCEMENT_MODAL &&
    !hasDismissedSaleAnnouncement
  ) {
    return PromoModalType.SALE_ANNONCEMENT_MODAL;
  }

  if (
    isLoggedInRetailer &&
    modalType === PromoModalType.INSIDER_REFRESH_REMINDER_MODAL
  ) {
    return PromoModalType.INSIDER_REFRESH_REMINDER_MODAL;
  }

  if (
    isLoggedInRetailer &&
    modalType === PromoModalType.INSIDER_CAN_AUS_LAUNCH_MODAL
  ) {
    return PromoModalType.INSIDER_CAN_AUS_LAUNCH_MODAL;
  }

  return null;
};

// Don't export this to UI code, because it's not safe to use storage in SSR.
// Create wrapper hooks instead.
export const usePromoModalStore = () => {
  const [{ hasUserClosedModal }, setStore] = usePromoModalContextStore([
    "hasUserClosedModal",
  ]);

  const modalType = getPromoModalInformation().type;
  const isInOpenWithFaireApplicationPage = !!pathMatchesRoute(
    getWindowOrThrow().location.pathname,
    netTermsOpenWithFaireApplicationRoute
  );

  const isInBlockedPath = PROMOTION_BLOCKED_PATHS.some(
    (route) =>
      !!matchPathWithLocale(getWindowOrThrow().location.pathname, route)
  );

  const modalToDisplay = useModalToDisplay(modalType);
  const closeModal = useCallback(() => {
    setStore({
      hasUserClosedModal: true,
    });
  }, [setStore]);

  return {
    modalToDisplay:
      isInBlockedPath ||
      hasUserClosedModal ||
      isInOpenWithFaireApplicationPage ||
      isUndefined(modalType) ||
      modalType === PromoModalType.PROTECTED_FOR_NEW_RETAILER
        ? null
        : modalToDisplay,
    closeModal,
  };
};
