"use client";

import { Core } from "@faire/design-tokens";
import { useMutation as useSearchSuggestionMutation } from "@faire/web-api/api/v2/search/products/from-brand/suggestions/post-hooks";
import { trackSearchSuggestionClick } from "@faire/web-api/events/search/click/suggestion";
import { trackSearchSuggestionsView } from "@faire/web-api/events/search/view/suggestions";
import { IBrandPageSearchSuggestionsRequest } from "@faire/web-api/indigofair/data/IBrandPageSearchSuggestionsRequest";
import { IBrandPageSearchSuggestionsResponse } from "@faire/web-api/indigofair/data/IBrandPageSearchSuggestionsResponse";
import { QueryParameters as BrandPageQueryParams } from "@faire/web-api/routes/brand/brandToken";
import { useStrictLocalization } from "@faire/web/common/localization";
import { Opacity } from "@faire/web/slate/Color";
import { Family } from "@faire/web/slate/Font";
import { Search } from "@faire/web/slate/icons/Search";
import { Column, Flex, Row } from "@faire/web/slate/Layout";
import { SearchInput } from "@faire/web/slate/SearchInput";
import { getSpacing, Spacer } from "@faire/web/slate/spacing";
import { responsiveMediaQueries } from "@faire/web/slate/Theme/responsiveMediaQueries";
import { Typography, TypographyStyles } from "@faire/web/slate/Typography";
import ClickOutside from "@faire/web/ui/ClickOutside";
import { useDebouncedValue } from "@faire/web/ui/hooks/useDebouncedValue";
import { useDoActionWhenVisible } from "@faire/web/ui/hooks/useDoActionWhenVisible";
import { useQueryParams } from "@faire/web/ui/hooks/useQueryParams";
import { useState } from "@faire/web/ui/hooks/useState";
import { observer } from "mobx-react";
import { useCallback, useEffect, useRef } from "react";
import * as React from "react";
import styled from "styled-components";

import StringHighlight from "@faire/retailer/components/TopSearch/ResultDropdown/StringHighlight";
import { stringOrUndefined } from "@faire/retailer/lib/stringOrUndefined";

interface IProps {
  autoFocus?: boolean;
  ["data-test-id"]?: string;
  search: (query: string, refType?: string, refReqId?: string) => void;
  className?: string;
  placeholder?: string;
  onEnterKeyPressed?: () => void;
  brandToken: string;
}

const BrandPageSearchBase: React.FC<IProps> = (props) => {
  const queryParams = useQueryParams(BrandPageQueryParams.parse);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [inputValue, setInputValue] = useState(
    stringOrUndefined(queryParams.q) ?? ""
  );
  const [searchSuggestions, setSearchSuggestions] = useState<
    IBrandPageSearchSuggestionsResponse | undefined
  >(undefined);
  const [selected, setSelected] = useState<number>(-1);
  const currentIndex = useRef(-1);
  const searchQuery = useRef(inputValue);
  useEffect(() => {
    searchQuery.current = stringOrUndefined(queryParams.q) ?? "";
    setInputValue(stringOrUndefined(queryParams.q) ?? "");
  }, [queryParams.q]);

  const { strictLocalize } = useStrictLocalization();
  const [debouncedQuery, setDebouncedQuery] = useState<string | undefined>(
    undefined
  );
  const debouncedSearchValue = useDebouncedValue(debouncedQuery, 300);
  const isFirstRender = useRef(true);

  const allSuggestions: string[] =
    searchSuggestions?.suggestions?.flatMap(
      (suggestions) => suggestions.suggested_queries
    ) ?? [];

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    const RETURN_KEY = 13;
    const ESCAPE_KEY = 27;
    const ARROW_UP = 38;
    const ARROW_DOWN = 40;

    switch (event.keyCode || event.key) {
      case RETURN_KEY:
      case "Enter":
        event.preventDefault();
        if (selected > -1) {
          const query = allSuggestions[selected] ?? "";
          setInputValue(query);
          props.search(
            query,
            searchSuggestions?.referrer_for_search?.referrer_type,
            searchSuggestions?.referrer_for_search?.referrer_request_id ?? ""
          );
          setSelected(-1);
        } else {
          props.search(value);
        }
        setShowSearchDropdown(false);

        if (props.onEnterKeyPressed) {
          props.onEnterKeyPressed();
        }
        break;
      case ESCAPE_KEY:
      case "Escape":
        event.preventDefault();
        handleClear();
        break;
      case ARROW_UP:
        event.preventDefault();
        setSelected(selected > -1 ? selected - 1 : selected);
        break;
      case ARROW_DOWN:
        event.preventDefault();
        setSelected(
          selected < allSuggestions.length - 1 ? selected + 1 : selected
        );
        break;
      default:
        break;
    }
  };

  const { mutate: getSearchSuggestions } = useSearchSuggestionMutation({
    onSuccess: (data) => {
      setSearchSuggestions(data);
      setShowSearchDropdown(true);
      setDebouncedQuery(undefined);
    },
    onError: () => {
      setSearchSuggestions(undefined);
    },
  });

  const handleClear = () => {
    setInputValue("");
    searchQuery.current = "";
    props.search("");
  };

  const onClickOutside = () => {
    setShowSearchDropdown(false);
  };

  const focusSearchBar = () => {
    fetchSearchSuggestions(searchQuery.current);
    setSelected(-1);
  };

  const fetchSearchSuggestions = useCallback(
    (queryValue: string) => {
      getSearchSuggestions([
        IBrandPageSearchSuggestionsRequest.build({
          brand_token: props.brandToken ?? "",
          query: queryValue,
        }),
      ]);
    },
    [props.brandToken, getSearchSuggestions]
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (debouncedSearchValue !== undefined) {
        fetchSearchSuggestions(debouncedSearchValue);
      }
    }
  }, [debouncedSearchValue, fetchSearchSuggestions]);

  const getDropdownSuggestions = () => {
    return searchSuggestions?.suggestions?.map((suggestions, sectionIndex) => {
      return (
        <SearchSuggestionSection
          suggestions={searchSuggestions}
          section={suggestions}
          sectionIndex={sectionIndex}
          query={searchQuery.current}
          key={sectionIndex}
          currentIndex={currentIndex.current}
          selected={selected}
          setSelected={setSelected}
          onClick={(suggested_query: string, index: number) => {
            trackSearchSuggestionClick(
              `${searchSuggestions.referrer_for_search?.referrer_type}|${sectionIndex}`,
              searchSuggestions.referrer_for_search?.referrer_request_id ?? "",
              `${inputValue}|${suggested_query}|${index}`
            );
            setInputValue(suggested_query);
            searchQuery.current = suggested_query;
            props.search(
              suggested_query,
              searchSuggestions.referrer_for_search?.referrer_type,
              searchSuggestions.referrer_for_search?.referrer_request_id ?? ""
            );
            setShowSearchDropdown(false);
            props.onEnterKeyPressed?.();
          }}
        />
      );
    });
  };

  return (
    <>
      <SearchWrapper onClickOutside={onClickOutside}>
        <FullWidthSearchField
          id="brand-search"
          value={inputValue}
          onClear={handleClear}
          onFocus={focusSearchBar}
          onChange={(e) => {
            const value = e.currentTarget.value;
            setInputValue(value);
            searchQuery.current = value;
            setDebouncedQuery(value);
          }}
          onKeyDown={handleKeyDown}
          placeholder={
            showSearchDropdown
              ? ""
              : props.placeholder ??
                strictLocalize({
                  defaultMessage: "Search all products",
                  description: {
                    text: "Text for search box that searches products in a brand.",
                  },
                })
          }
          data-test-id={props["data-test-id"]}
          autoFocus={props.autoFocus}
          className={props.className}
        />
        {showSearchDropdown ? getDropdownSuggestions() : null}
      </SearchWrapper>
    </>
  );
};

const SearchSuggestionSection: React.FC<{
  suggestions?: IBrandPageSearchSuggestionsResponse;
  section: IBrandPageSearchSuggestionsResponse.ISuggestion;
  sectionIndex: number;
  query: string;
  onClick: (suggested_query: string, index: number) => void;
  selected: number;
  setSelected?: (index: number) => void;
  currentIndex: number;
}> = ({
  suggestions,
  section,
  sectionIndex,
  onClick,
  query,
  selected,
  setSelected,
  currentIndex,
}) => {
  const ref = useDoActionWhenVisible(() => {
    trackSearchSuggestionsView(
      `${suggestions?.referrer_for_search?.referrer_type}|${sectionIndex}`,
      suggestions?.referrer_for_search?.referrer_request_id ?? "",
      `${query}|${section.suggested_queries.join("|")}`
    );
  });
  return (
    <ResultDropdown gap="1x" ref={ref}>
      {section.suggestion_header ? (
        <ItemWrapper>
          <Typography variant="paragraphSansMedium">
            {section.suggestion_header}
          </Typography>
          <Spacer height="1x" />
        </ItemWrapper>
      ) : null}
      {section.suggested_queries.map((suggested_query, index) => {
        currentIndex++;
        return (
          <DropdownItem
            key={suggested_query}
            label={suggested_query}
            index={currentIndex}
            selectedIndex={selected}
            onHover={setSelected}
            onClick={() => onClick(suggested_query, index)}
            query={query}
          />
        );
      })}
    </ResultDropdown>
  );
};

const DropdownItem: React.FC<{
  label: string;
  index: number;
  selectedIndex: number;
  query: string;
  onHover?: (index: number) => void;
  onClick?: () => void;
}> = ({ label, index, selectedIndex, onHover, onClick, query }) => {
  return (
    <ItemWrapper
      gap="2x"
      align="center"
      $selected={selectedIndex === index}
      onMouseEnter={() => onHover?.(index)}
      onClick={(e) => {
        e.preventDefault();
        onClick?.();
      }}
    >
      <IconWrapper>
        <Search
          height="16px"
          width="16px"
          color={Core.text.default}
          strokeWidth={1}
        />
      </IconWrapper>
      <Typography>
        <StringHighlight message={label} highlight={query} reverseHighlight />
      </Typography>
    </ItemWrapper>
  );
};

export const BrandPageSearch = observer(BrandPageSearchBase);

const FullWidthSearchField = styled(SearchInput)`
  width: 100%;
`;

const ResultDropdown = styled(Column)`
  width: 100%;
  background: ${Core.surface.inverse};
  padding: ${getSpacing("2x", 0, "2x", 0)};
  z-index: 12;
  ${responsiveMediaQueries.mobile} {
    position: fixed;
    top: 70px;
    left: 0px;
  }
  ${responsiveMediaQueries.tabletAndAbove} {
    position: absolute;
    top: 50px;
  }
  ${responsiveMediaQueries.desktopAndAbove} {
    box-shadow: 0 7px 10px ${Core.surface.enabled + Opacity.O_10};
  }
  overflow-y: auto;
`;

const IconWrapper = styled(Flex).attrs({
  justify: "center",
  align: "center",
})`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${Core.surface.subdued};
`;

const ItemWrapper = styled(Row)<{ $selected?: boolean }>`
  background-color: ${({ $selected }) =>
    $selected ? Core.surface.subdued + Opacity.O_50 : undefined};
  padding: ${getSpacing(4, "2x", 4, "2x")};
  strong {
    ${TypographyStyles({ truncate: true, variant: "paragraphSansMedium" })}
  }
`;

const SearchWrapper = styled(ClickOutside)`
  font-family: ${Family.sansSerif};
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: 50%;
  z-index: 11;
  ${responsiveMediaQueries.mobile} {
    width: 100%;
  }
  ${responsiveMediaQueries.tabletAndAbove} {
    flex-grow: 1;
  }
  background: white;
`;
