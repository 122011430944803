import { match as matchBrandRoute } from "@faire/web-api/routes/brand/brandToken/match";
import { match as matchProductRoute } from "@faire/web-api/routes/product/productToken/match";
import { Storage } from "@faire/web/common/Storage";
import { isNullish } from "@faire/web/common/typescriptUtils";
import { matchPathWithLocale } from "@faire/web/ui/routing/react-utils";
import { removeLocaleFromUrl } from "@faire/web/ui/routing/util";
import { useMemo } from "react";

import { useZustandLocation } from "@faire/retailer/app/_lib/routing/useZustandLocation";
import { useFaireDirectFirstOrderIncentive } from "@faire/retailer/hooks/FaireDirect/useFaireDirectFirstOrderIncentive/useFaireDirectFirstOrderIncentive";
import { useFaireDirectMarketplaceIncentive } from "@faire/retailer/hooks/FaireDirect/useFaireDirectMarketplaceIncentive/useFaireDirectMarketplaceIncentive";
import { LOCAL_STORAGE_KEY } from "@faire/retailer/lib/localSession";
import { useSettingFaireDirectEnableTargetedRecurringMarketplaceModal } from "@faire/retailer/settings/useSettingFaireDirectEnableTargetedRecurringMarketplaceModal";
import { useSettingFaireDirectRecurringMarketplaceEligibleRoutes } from "@faire/retailer/settings/useSettingFaireDirectRecurringMarketplaceEligibleRoutes";
import { useSettingFaireDirectRecurringMarketplaceModalVisitThreshold } from "@faire/retailer/settings/useSettingFaireDirectRecurringMarketplaceModalVisitThreshold";
import { useMilestone } from "@faire/retailer/stores/domain/useMilestone";
import { useFetchCachedBrandView } from "@faire/retailer/views/brand/hooks/useFetchCachedBrandView";
import { isReorderArchetype } from "@faire/retailer/views/brand/isReorderArchetype";
import { useLeanProductWithMaybeReactQuery } from "@faire/retailer/views/product/utils/useLeanProductQuery";

import { usePromoModalStore } from "./usePromoModalStore";

export const useShouldNotShowFaireDirectIncentiveModals = () => {
  const { hasIncentive: hasFaireDirectFirstOrderIncentive } =
    useFaireDirectFirstOrderIncentive();
  const { hasIncentive: hasFaireDirectMarketplaceIncentive } =
    useFaireDirectMarketplaceIncentive();
  const isFaireDirectTargetedMarketplaceModalEnabled =
    useSettingFaireDirectEnableTargetedRecurringMarketplaceModal();
  const isEligibleForTargetedMarketplaceModal =
    useIsEligibleForTargetedFaireDirectRecurringMarketplaceModal();
  const { hasOccurred: hasDismissedFaireDirectMarketplaceModal } = useMilestone(
    "FD_MARKETPLACE_DISCOUNT_MODAL_DISMISSED"
  );

  const { modalToDisplay } = usePromoModalStore();

  const shouldNotShowFaireDirectFirstOrderIncentiveModal =
    modalToDisplay === "FAIRE_DIRECT_INCENTIVE_MODAL" &&
    !hasFaireDirectFirstOrderIncentive;

  const shouldHideModalNoMarketplaceIncentive =
    (modalToDisplay === "FAIRE_DIRECT_MARKETPLACE_DISCOUNT_MODAL" ||
      modalToDisplay === "FAIRE_DIRECT_EVERGREEN_MARKETPLACE_DISCOUNT_MODAL") &&
    !hasFaireDirectMarketplaceIncentive;

  // if retailer is eligible to see the marketplace modal and they are in treatment, recalculate
  const shouldHideTargetedMarketplaceDiscountModal =
    isFaireDirectTargetedMarketplaceModalEnabled &&
    modalToDisplay === "FAIRE_DIRECT_MARKETPLACE_DISCOUNT_MODAL" &&
    hasDismissedFaireDirectMarketplaceModal &&
    !isEligibleForTargetedMarketplaceModal;

  const shouldNotShowFaireDirectMarketplaceIncentiveModal =
    shouldHideModalNoMarketplaceIncentive ||
    shouldHideTargetedMarketplaceDiscountModal;

  return (
    shouldNotShowFaireDirectFirstOrderIncentiveModal ||
    shouldNotShowFaireDirectMarketplaceIncentiveModal
  );
};

// Custom hook to check if retailer is eligible for the targeted FD Marketplace modal
const useIsEligibleForTargetedFaireDirectRecurringMarketplaceModal =
  (): boolean => {
    const location = useZustandLocation((state) => state.pathname);

    const eligibleRoutes =
      useSettingFaireDirectRecurringMarketplaceEligibleRoutes();
    const threshold =
      useSettingFaireDirectRecurringMarketplaceModalVisitThreshold();
    const lastVisitAt = getLastTimeSeen();

    const currentPath = location.pathname;

    const brandTokenMatch = matchBrandRoute(removeLocaleFromUrl(currentPath))
      ?.params.brandToken;
    const productTokenMatch = matchProductRoute(
      removeLocaleFromUrl(currentPath)
    )?.params.productToken;

    const productData = useLeanProductWithMaybeReactQuery(productTokenMatch);

    const brandToken = useMemo(() => {
      if (brandTokenMatch) {
        return brandTokenMatch;
      } else if (productData?.brand?.token) {
        return productData.brand.token;
      }
      return undefined;
    }, [brandTokenMatch, productData]);

    const { data: brandViewData } = useFetchCachedBrandView(brandToken ?? "");

    if (lastVisitAt === null) {
      return false;
    }

    const timeSinceLastVisit = Date.now() - lastVisitAt;

    if (timeSinceLastVisit < threshold) {
      return false;
    }

    /* Check if route is
     ** 1. Homepage or
     ** 2. In one of the allowed routes or
     ** 3. In one of the not-ordered allowed routes without a previous order from the brand
     */
    const eligible =
      currentPath === "/" ||
      eligibleRoutes.some((path) => matchPathWithLocale(currentPath, path)) ||
      (!isNullish(brandViewData) && !isReorderArchetype(brandViewData));

    return eligible;
  };

const getLastTimeSeen = (): number | null => {
  let sessionData;
  try {
    sessionData = JSON.parse(Storage.local.getItem(LOCAL_STORAGE_KEY) ?? "");
  } catch {
    return null;
  }

  const lastVisitAt = sessionData["updated-at"];
  if (isNullish(lastVisitAt)) {
    return null;
  }

  return Number(lastVisitAt) || 0;
};
