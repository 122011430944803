import { IUser } from "@faire/web-api/indigofair/data/IUser";
import { ISearchBrandProductsFiltersRequest } from "@faire/web-api/indigofair/data/ISearchBrandProductsFiltersRequest";
import type { ISearchMakerProductsRequest } from "@faire/web-api/indigofair/data/ISearchMakerProductsRequest";
import { ISearchProductsFromBrandRequest } from "@faire/web-api/indigofair/data/ISearchProductsFromBrandRequest";
import { ISearchRequestReferrer } from "@faire/web-api/indigofair/data/ISearchRequestReferrer";
import {
  getEstimatedViewportType,
  IEstimatedViewport,
} from "@faire/web/common/user-agent/getEstimatedViewportType";

import {
  buildCategoryNavigationPath,
  getUrlIdsFromNavigationPath,
} from "../categories/navigationPath";

import { BrandPageQueryObj } from "./BrandPageQueryObj";

export const returnRetrievalRankingDebugInfo = (
  user: IUser | undefined,
  settingShouldDisplayRetrievalAndRankingDebugInfo: boolean
) => {
  if (!(user?.is_employee || user?.impersonating)) {
    return false;
  }
  return settingShouldDisplayRetrievalAndRankingDebugInfo;
};

export function loggedInBrandLeanProductTilesRequest(
  queryParams: BrandPageQueryObj,
  clientSize: keyof typeof ISearchMakerProductsRequest.ClientSize,
  brandToken: string | undefined,
  user?: IUser | undefined,
  settingShouldDisplayRetrievalAndRankingDebugInfo?: boolean
): ISearchProductsFromBrandRequest {
  /**
   * when adding a brand query parameter that affects search
   * don't forget to pass them to and from the product page
   *
   * This preserves the same product search results in the brand page
   * when navigating from brand->product->brand
   *
   * to: retailer/views/brand/utils/generateProductUrlForBrandPage.ts
   * from: retailer/views/product/utils/generateBrandUrlForProductPage.ts
   * from: src/views/product/utils/generateProductUrlForProductPage.ts
   * - brand->product->product->brand
   */

  return ISearchProductsFromBrandRequest.build({
    brand_token: brandToken,
    page_number: getPageNumber(queryParams),
    page_size: getPageSize(clientSize),
    include_sales_paused: true,
    query: queryParams.q,
    filter_keys: queryParams.filters ?? [],
    client_size: clientSize,
    referrer: getReferrer(queryParams),
    ranking_boost: getRankingBoost(queryParams),
    return_retrieval_and_ranking_debug_info: returnRetrievalRankingDebugInfo(
      user,
      settingShouldDisplayRetrievalAndRankingDebugInfo ?? false
    ),
  });
}

export function searchBrandProductsFiltersRequestFromProductTilesRequest(
  request: ISearchProductsFromBrandRequest
): ISearchBrandProductsFiltersRequest {
  // It is important that we don't do `{ ...request }` because cachingApi generates a cache key
  // from the request object. Any extra attributes may cause unnecessary cache misses.
  return ISearchBrandProductsFiltersRequest.build({
    brand_token: request.brand_token,
    query: request.query,
    filter_keys: request.filter_keys,
    client_size: request.client_size,
  });
}

function getReferrer(queryParams: BrandPageQueryObj): ISearchRequestReferrer {
  const { refReqId: referrerRequestId, refType } = queryParams;
  const referrerType =
    ISearchRequestReferrer.SearchRequestReferrerType[
      (refType as ISearchRequestReferrer.SearchRequestReferrerType) ?? "NONE"
    ];
  return ISearchRequestReferrer.build({
    referrer_type:
      referrerType ??
      ISearchRequestReferrer.SearchRequestReferrerType
        .SEARCH_REQUEST_REFERRER_TYPE_UNKNOWN,
    referrer_request_id: referrerRequestId,
  });
}

export function getPageNumber(queryParams: BrandPageQueryObj): number {
  return queryParams.page ?? 1;
}

export function getPageSize(
  clientSize: keyof typeof ISearchMakerProductsRequest.ClientSize
): number {
  return clientSize === "DESKTOP" ? 60 : 20;
}

function getRankingBoost(
  queryParams: BrandPageQueryObj
): ISearchMakerProductsRequest.IRankingBoost | undefined {
  const [referrerCategory, ...referrerSubCategories] =
    getUrlIdsFromNavigationPath(queryParams.refC ?? "");
  return {
    should_use_sorting_hint: queryParams.sortingHint || undefined,
    referrer_category: referrerCategory ? referrerCategory : undefined,
    referrer_sub_category:
      referrerSubCategories.length > 0
        ? buildCategoryNavigationPath(referrerSubCategories)
        : undefined,
    sort_by: queryParams.sortBy || undefined,
    selected_category: queryParams.cat || undefined,
    referrer_search: queryParams.refS || undefined,
    referrer_filters: queryParams.refF || [],
    referrer_products: queryParams.refP || [],
  };
}

export function getEstimatedClientSize(
  estimatedViewport?: IEstimatedViewport
): keyof typeof ISearchMakerProductsRequest.ClientSize {
  const { type } = estimatedViewport ?? getEstimatedViewportType();
  return type === "desktop" || type === "xLarge" ? "DESKTOP" : "MOBILE";
}
